import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Checkbox,
  Select,
  MenuItem
} from "@material-ui/core";
import SelectBoxIcon from "@material-ui/icons/ExpandMore";
import TermsAndConditonsModal from "../cars/TermsAndConditions";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

const styles = { marginTop: 30 };

const CarCustomerInfoWeb = props => {
  let {
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    handleValidDate,
    errMsg,
    isLoading,
    startDate,
    dropOffArr,
    pickUpArr,
    maxRentalPeriod
  } = props;
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [returnDateFocus, setReturnDateFocus] = useState(false);
  const [checkedTerm, setChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [startDateChange, setStartDateChange] = useState(false);
  const [startDateVal, setStartDateVal] = useState(startDate);

  const isOutsideRange = day => {
    const maxDaysLater = moment(startDateVal).add(maxRentalPeriod, "days");
    return (
      day.isBefore(moment(startDateVal).add(1, "days"), "day") ||
      day.isAfter(maxDaysLater, "day")
    );
  };

  useEffect(() => {
    if (startDateChange) {
      setStartDateVal(values.start_date);
    }
  }, [setFieldValue, startDateChange, values.start_date]);

  return (
    <React.Fragment>
      <form
        style={{
          // position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          // overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "80px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "#273142"
        }}
      >
        <Grid container className="customerContainer car-personal-info">
          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item xs={8} sm={8}>
            <span className="title" style={{ color: "#ffffff" }}>
              Personal Information
            </span>
          </Grid>
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item md={8}>
            <Grid container className="contactInfo">
              <Grid
                item
                md={6}
                className={
                  errors.employee_name && touched.employee_name
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">Employee Name</span>
                <span className="required">*</span>
                <TextField
                  name="employee_name"
                  value={values.employee_name || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                />
                {errors.employee_name && touched.employee_name && (
                  <span className="errMessage">{errors.employee_name}</span>
                )}
              </Grid>
              <Grid item sm={6}>
                <span className="fieldTitle">Item</span>
                <TextField
                  name="unit_id"
                  value={values.name || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  disabled
                />
              </Grid>

              <Grid item sm={6} className="carStartDate">
                <Grid container className="date-region">
                  <div className="date-inner car-date start-date">
                    <span className="fieldTitle">
                      Pickup Date <span className="required">*</span>
                    </span>
                    <div className="start-date-time-region start-time">
                      <SingleDatePicker
                        id="carStartdate"
                        date={moment(values.start_date)}
                        placeholder="Start Date"
                        focused={startDateFocus}
                        onFocusChange={() => {
                          setStartDateFocus(true);
                        }}
                        onDateChange={date => {
                          setStartDateChange(true);
                          setFieldValue(
                            "start_date",
                            date.format("YYYY-MM-DD")
                          );
                          setFieldValue(
                            "return_date",
                            date.add(1, "days").format("YYYY-MM-DD")
                          );
                        }}
                        numberOfMonths={1}
                        navPrev={
                          <div className="arrow-left" id="prevMonth"></div>
                        }
                        navNext={
                          <div className="arrow-right" id="nextMonth"></div>
                        }
                        displayFormat="YYYY-MM-DD"
                        hideKeyboardShortcutsPanel={true}
                        keepOpenOnDateSelect={false}
                        onClose={() => setStartDateFocus(false)}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        daySize={30}
                        transitionDuration={0}
                        isOutsideRange={date => date.isBefore(moment(), "day")}
                        readOnly
                      />
                      <TimePicker
                        onChange={val => {
                          setFieldValue(
                            "start_time",
                            val ? moment(val).format("HH:mm") : ""
                          );
                        }}
                        value={
                          values.start_time &&
                          moment(values.start_time, "hh:mm A")
                            ? moment(values.start_time, "hh:mm A")
                            : null
                        }
                        placeholder="00:00 AM"
                        showSecond={false}
                        format="hh:mm A"
                        use12Hours
                        inputReadOnly
                        minuteStep={5}
                        className={
                          errors.start_time && touched.start_time
                            ? "searchErrMessage"
                            : ""
                        }
                      />
                    </div>
                    {errors.start_time && touched.start_time && (
                      <span className="errMessage">{errors.start_time}</span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                <Grid container className="date-region">
                  <div className="date-inner car-date end-date">
                    <span className="fieldTitle">
                      Return Date <span className="required">*</span>
                    </span>
                    <div className="start-date-time-region end-time">
                      <SingleDatePicker
                        id="carStartdate"
                        date={moment(values.return_date)}
                        placeholder="Return Date"
                        focused={returnDateFocus}
                        onFocusChange={() => setReturnDateFocus(true)}
                        onDateChange={date => {
                          setFieldValue(
                            "return_date",
                            date.format("YYYY-MM-DD")
                          );
                        }}
                        numberOfMonths={1}
                        navPrev={
                          <div className="arrow-left" id="prevMonth"></div>
                        }
                        navNext={
                          <div className="arrow-right" id="nextMonth"></div>
                        }
                        displayFormat="YYYY-MM-DD"
                        hideKeyboardShortcutsPanel={true}
                        keepOpenOnDateSelect={false}
                        onClose={() => setReturnDateFocus(false)}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        daySize={30}
                        transitionDuration={0}
                        isOutsideRange={isOutsideRange}
                        readOnly
                      />
                      <TimePicker
                        onChange={val => {
                          setFieldValue(
                            "return_time",
                            val ? moment(val).format("HH:mm") : ""
                          );
                        }}
                        value={
                          values.return_time &&
                          moment(values.return_time, "hh:mm A")
                            ? moment(values.return_time, "hh:mm A")
                            : null
                        }
                        placeholder="00:00 AM"
                        showSecond={false}
                        format="hh:mm A"
                        use12Hours
                        inputReadOnly
                        minuteStep={5}
                        className={
                          errors.return_time && touched.return_time
                            ? "searchErrMessage"
                            : ""
                        }
                      />
                    </div>
                    {errors.return_time && touched.return_time && (
                      <span className="errMessage">{errors.return_time}</span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                className={
                  errors.email && touched.email ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Email</span>
                <span className="required">*</span>
                <TextField
                  name="email"
                  type="text"
                  value={values.email || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <span className="errMessage">{errors.email}</span>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px !important" }}
                className={
                  errors.phone && touched.phone ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Phone</span>
                <span className="required">*</span>
                <TextField
                  name="phone"
                  type="text"
                  value={values.phone || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && touched.phone && (
                  <span className="errMessage">{errors.phone}</span>
                )}
              </Grid>

              <Grid
                item
                sm={6}
                className={
                  errors.pickup_location && touched.pickup_location
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">Pickup Location</span>
                <span className="required">*</span>
                <Select
                  onChange={e => {
                    setFieldValue("pickup_location", e.target.value);
                  }}
                  IconComponent={SelectBoxIcon}
                  className="pickupLocation"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  value={values.pickup_location || ""}
                >
                  {pickUpArr.length > 0 &&
                    pickUpArr.map((item, index) => (
                      <MenuItem value={item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.pickup_location && touched.pickup_location && (
                  <span className="errMessage">{errors.pickup_location}</span>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                className={
                  errors.dropoff_location && touched.dropoff_location
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">Drop-off Location</span>
                <span className="required">*</span>
                <Select
                  onChange={e => {
                    setFieldValue("dropoff_location", e.target.value);
                  }}
                  IconComponent={SelectBoxIcon}
                  className="dropoffLocation"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  value={values.dropoff_location || ""}
                >
                  {dropOffArr.length > 0 &&
                    dropOffArr.map((item, index) => (
                      <MenuItem value={item.name} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.dropoff_location && touched.dropoff_location && (
                  <span className="errMessage">{errors.dropoff_location}</span>
                )}
              </Grid>

              <Grid item md={6}>
                <span className="fieldTitle">Note</span>
                <TextField
                  name="notes"
                  value={values.notes || ""}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item sm={6} className="car-reason">
                <span className="fieldTitle">Reason for Reservation</span>
                <TextField
                  name="reason"
                  value={values.reason || ""}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>

            <Grid item sm={12} style={styles}>
              <TermsAndConditonsModal
                showConfirmation={showTerms}
                handleClose={() => setShowTerms(false)}
                isLoading={props.isLoading}
                handleContinue={handleSubmit}
              />

              <div
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  marginTop: "15px",
                  display: "block",
                  marginRight: "10px"
                }}
              >
                <div
                  className="MuiGrid-root contactInfo MuiGrid-container"
                  style={{ background: "#ffcc00" }}
                >
                  <div
                    style={{
                      display: "block",
                      float: "left",
                      fontSize: "40px",
                      lineHeight: "40px",
                      width: "44px",
                      height: "44px",
                      padding: "0px 6px",
                      color: "#800000"
                    }}
                  >
                    ⚠
                  </div>
                  <div
                    style={{
                      display: "block",
                      color: "#000000"
                    }}
                  >
                    <div
                      style={{
                        display: "block"
                      }}
                    >
                      Please email your insurance cards to&nbsp;
                      <b>starbaseteslareservations@spacex.com</b>
                    </div>
                    <div
                      style={{
                        display: "block"
                      }}
                    >
                      In order the travel manager to accept your car request.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center"
                  }}
                >
                  <Checkbox
                    value={checkedTerm}
                    checked={checkedTerm}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                    onChange={(e, v) => {
                      setChecked(v);
                    }}
                  />
                  By clicking "Confirm Booking" you are agreeing to the{" "}
                  <span
                    onClick={() => setShowTerms(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <u>Terms and Conditons</u>
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  id="fl-customer-btn-continue"
                  className="btnContinue pull-right"
                  onClick={handleSubmit}
                  disabled={!checkedTerm}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    float: "none !important"
                  }}
                >
                  Confirm Booking{" "}
                  {!handleValidDate ||
                    (errMsg !== "" ? <CircularProgress /> : "") ||
                    (isLoading ? <CircularProgress /> : "")}
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default CarCustomerInfoWeb;
