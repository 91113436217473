import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Checkbox,
  Select
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import TermsAndConditonsModal from "../cars/TermsAndConditions";
import { SingleDatePicker } from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

const CarCustomerInfoMobile = props => {
  let {
    values,
    handleBlur,
    handleChange,
    isLoading,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    handleValidDate,
    errMsg,
    startDate,
    dropOffArr,
    pickUpArr,
    maxRentalPeriod
  } = props;
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [returnDateFocus, setReturnDateFocus] = useState(false);
  const [checkedTerm, setChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [startDateChange, setStartDateChange] = useState(false);
  const [startDateVal, setStartDateVal] = useState(startDate);

  const isOutsideRange = day => {
    const maxDaysLater = moment(startDateVal).add(maxRentalPeriod, "days");
    return (
      day.isBefore(moment(startDateVal).add(1, "days"), "day") ||
      day.isAfter(maxDaysLater, "day")
    );
  };

  useEffect(() => {
    if (startDateChange) {
      setStartDateVal(values.start_date);
    }
  }, [setFieldValue, startDateChange, values.start_date]);

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          //position: "fixed",
          overflow: "auto",
          display: "block",
          padding: "0",
          margin: "0",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid
          item
          xs={12}
          className="contactMobileTitle"
          style={{ backgroundColor: "#273142" }}
        >
          <span
            className="title"
            style={{ paddingLeft: "15px", color: "#fff" }}
          >
            Personal Information
          </span>
        </Grid>

        <Grid
          item
          xs={12}
          className="contactMobile car-personal-info car-personal-info-mobile"
          style={{ margin: "5px" }}
        >
          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <TextField
              error={
                errors.employee_name && touched.employee_name ? true : false
              }
              name="employee_name"
              label={"Employee Name"}
              value={values.employee_name}
              autoComplete="off"
              onChange={handleChange}
              helperText={
                errors.employee_name && touched.employee_name
                  ? errors.employee_name
                  : ""
              }
              required={true}
              onBlur={handleBlur}
            />
          </Grid>

          <TextField
            error={errors.name && touched.name ? true : false}
            id="standard-name"
            name="avgWork"
            value={values.name}
            onBlur={handleBlur}
            label={"Item"}
            onChange={handleChange}
            autoComplete="off"
            helperText={errors.name && touched.name ? errors.name : ""}
            required={true}
            disabled
          />

          <Grid
            item
            sm={6}
            className={
              errors.avgWork && touched.avgWork ? "searchErrMessage" : ""
            }
          >
            <Grid container className="date-region">
              <span className="fieldTitle">Pickup Date</span>
              <div className="date-inner car-date">
                <SingleDatePicker
                  id="carStartdate"
                  date={moment(values.start_date)}
                  placeholder="Start Date"
                  focused={startDateFocus}
                  onFocusChange={() => {
                    setStartDateFocus(true);
                  }}
                  onDateChange={date => {
                    setStartDateChange(true);
                    setFieldValue("start_date", date.format("YYYY-MM-DD"));
                    setFieldValue(
                      "return_date",
                      date.add(1, "days").format("YYYY-MM-DD")
                    );
                  }}
                  numberOfMonths={1}
                  navPrev={<div className="arrow-left" id="prevMonth"></div>}
                  navNext={<div className="arrow-right" id="nextMonth"></div>}
                  displayFormat="YYYY-MM-DD"
                  hideKeyboardShortcutsPanel={true}
                  keepOpenOnDateSelect={true}
                  onClose={() => setStartDateFocus(false)}
                  showDefaultInputIcon
                  inputIconPosition="after"
                  daySize={30}
                  transitionDuration={0}
                  isOutsideRange={date => date.isBefore(moment(), "day")}
                  readOnly
                />
                <TimePicker
                  onChange={val => {
                    setFieldValue(
                      "start_time",
                      val ? moment(val).format("HH:mm") : ""
                    );
                  }}
                  value={
                    values.start_time && moment(values.start_time, "hh:mm A")
                      ? moment(values.start_time, "hh:mm A")
                      : null
                  }
                  placeholder="00:00 AM"
                  showSecond={false}
                  format="hh:mm A"
                  use12Hours
                  inputReadOnly
                  minuteStep={5}
                  className={
                    errors.start_time && touched.start_time
                      ? "searchErrMessage"
                      : ""
                  }
                />
                {errors.start_time && touched.start_time && (
                  <span className="errMessage">{errors.start_time}</span>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid item sm={6} style={{ marginBottom: "25px" }}>
            <Grid container className="date-region">
              <span className="fieldTitle">Return Date</span>
              <div className="date-inner car-date">
                <SingleDatePicker
                  id="carStartdate"
                  date={moment(values.return_date)}
                  placeholder="Return Date"
                  focused={returnDateFocus}
                  onFocusChange={() => setReturnDateFocus(true)}
                  onDateChange={date => {
                    setFieldValue("return_date", date.format("YYYY-MM-DD"));
                  }}
                  numberOfMonths={1}
                  navPrev={<div className="arrow-left" id="prevMonth"></div>}
                  navNext={<div className="arrow-right" id="nextMonth"></div>}
                  displayFormat="YYYY-MM-DD"
                  hideKeyboardShortcutsPanel={true}
                  keepOpenOnDateSelect={true}
                  onClose={() => setReturnDateFocus(false)}
                  showDefaultInputIcon
                  inputIconPosition="after"
                  daySize={30}
                  transitionDuration={0}
                  isOutsideRange={isOutsideRange}
                  readOnly
                />
                <TimePicker
                  onChange={val => {
                    setFieldValue(
                      "return_time",
                      val ? moment(val).format("HH:mm") : ""
                    );
                  }}
                  value={
                    values.return_time && moment(values.return_time, "hh:mm A")
                      ? moment(values.return_time, "hh:mm A")
                      : null
                  }
                  placeholder="00:00 AM"
                  showSecond={false}
                  format="hh:mm A"
                  use12Hours
                  inputReadOnly
                  minuteStep={5}
                  className={
                    errors.return_time && touched.return_time
                      ? "searchErrMessage"
                      : ""
                  }
                />
                {errors.return_time && touched.return_time && (
                  <span className="errMessage">{errors.return_time}</span>
                )}
              </div>
            </Grid>
          </Grid>

          <TextField
            error={errors.email && touched.email ? true : false}
            id="standard-name"
            name="email"
            value={values.email}
            label={"Email"}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.email && touched.email ? errors.email : ""}
            autoComplete="off"
            required={true}
          />
          <TextField
            error={errors.phone && touched.phone ? true : false}
            id="standard-name"
            name="phone"
            value={values.phone}
            label={"Phone"}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.phone && touched.phone ? errors.phone : ""}
            autoComplete="off"
            required={true}
          />

          <Grid item xs={12} className="pickupMobile">
            <span className="fieldTitle">
              Pickup Location<span className="required">*</span>
            </span>
            <Select
              native
              onChange={e => setFieldValue("pickup_location", e.target.value)}
              IconComponent={ExpandMoreIcon}
              className="pickupLocationMobile"
              value={values.pickup_location}
            >
              {pickUpArr.length > 0 &&
                pickUpArr.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12} className="dropOffMobile">
            <span className="fieldTitle">
              Drop-off Location<span className="required">*</span>
            </span>
            <Select
              native
              onChange={e => setFieldValue("dropoff_location", e.target.value)}
              IconComponent={ExpandMoreIcon}
              className="dropOffLocationMobile"
              value={values.dropoff_location}
            >
              {dropOffArr.length > 0 &&
                dropOffArr.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12} style={{}}>
            <TextField
              name="notes"
              value={values.notes}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Note"}
              multiline
              rows={4}
            />
          </Grid>
          <TextField
            name="reason"
            value={values.reason}
            label={"Reason for Reservation"}
            autoComplete="off"
            onBlur={handleBlur}
            onChange={handleChange}
            multiline
            rows={4}
          />
        </Grid>

        <TermsAndConditonsModal
          showConfirmation={showTerms}
          handleClose={() => setShowTerms(false)}
          isLoading={props.isLoading}
          handleContinue={handleSubmit}
        />
        <Grid
          item
          xs={12}
          className="continueBox"
          style={{
            background: "transparent",
            border: "0px"
          }}
        >
          <div
            style={{
              color: "#fff",
              fontSize: "14px",
              marginTop: "15px",
              display: "block"
            }}
          >
            <div
              className="MuiGrid-root contactInfo MuiGrid-container"
              style={{
                background: "#ffcc00",
                textAlign: "center",
                padding: "6px 0px 12px 0px"
              }}
            >
              <div
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontSize: "40px",
                  lineHeight: "40px",
                  width: "44px",
                  height: "44px",
                  padding: "0px 6px",
                  color: "#800000"
                }}
              >
                ⚠
              </div>
              <div
                style={{
                  display: "block",
                  color: "#000000"
                }}
              >
                <div
                  style={{
                    display: "block"
                  }}
                >
                  Please email your insurance cards to&nbsp;
                  <b>starbaseteslareservations@spacex.com</b>
                </div>
                <div
                  style={{
                    display: "block"
                  }}
                >
                  In order the travel manager to accept your car request.
                </div>
              </div>
            </div>
            <div
              style={{
                display: "block",
                width: "100%",
                textAlign: "center"
              }}
            >
              <Checkbox
                value={checkedTerm}
                checked={checkedTerm}
                color="primary"
                inputProps={{
                  "aria-label": "secondary checkbox"
                }}
                onChange={(e, v) => {
                  setChecked(v);
                }}
              />
              I accept the{" "}
              <span
                onClick={() => setShowTerms(true)}
                style={{ cursor: "pointer" }}
              >
                <u>Terms and Conditons</u>
              </span>
            </div>
          </div>
          <Button
            className="btnContinue pull-right"
            id="fl-customer-btn-continue"
            onClick={handleSubmit}
            disabled={!checkedTerm}
          >
            Confirm Booking{" "}
            {!handleValidDate ||
              (errMsg !== "" ? <CircularProgress /> : "") ||
              (isLoading ? <CircularProgress /> : "")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CarCustomerInfoMobile;
