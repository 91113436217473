import React, { useState } from "react";
import moment from "moment";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import CheckListIcon from "../../../assets/images/checklist-confirmation.svg";

const CarConfirmation = props => {
  const [personInfo] = useState(
    props.location.state && props.location.state.personInfo
      ? props.location.state.personInfo
      : null
  );

  return (
    <>
      <SpacexNavbar />
      <div style={{ minHeight: "100vh", backgroundColor: "#273142" }}>
        {personInfo ? (
          <div className="confirmation-upper">
            <img src={CheckListIcon} alt="Confirmation" />
            <span className="title" style={{ color: "#2cafe8" }}>
              Your reservation has been received.
            </span>
            <div
              className="MuiGrid-root confirmation-lower MuiGrid-container"
              style={{ maxWidth: "300px" }}
            >
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12">
                <div className="bookingdetail">
                  {/* <div className="bookingdiv">
                  <span className="bookingheader">Room Number</span>
                  <span className="bookingtext">{`${roomInfo.name} ${
                    roomInfo.room_id ? roomInfo.room_id : ""
                  }`}</span>
                </div> */}
                  <div className="bookingdiv">
                    <span className="bookingheader">Start Date</span>
                    <span className="bookingtext">
                      {personInfo.start_date
                        ? moment(personInfo.start_date, "YYYY-MM-DD").format(
                            "ddd, DD MMM YYYY"
                          ) +
                          "," +
                          personInfo.start_time
                        : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Employee Name</span>
                    <span className="bookingtext">
                      {personInfo.employee_name ? personInfo.employee_name : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Email</span>
                    <span className="bookingtext">{personInfo.email}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Phone</span>
                    <span className="bookingtext">{personInfo.phone}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Item</span>
                    <span className="bookingtext">{personInfo.name}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Pickup Location</span>
                    <span className="bookingtext">
                      {personInfo.pickup_location}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Dropoff Location</span>
                    <span className="bookingtext">
                      {personInfo.dropoff_location}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Return Date</span>
                    <span className="bookingtext">
                      {personInfo.return_date
                        ? moment(personInfo.return_date, "YYYY-MM-DD").format(
                            "ddd, DD MMM YYYY"
                          ) +
                          "," +
                          personInfo.return_time
                        : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Notes</span>
                    <span className="bookingtext">{personInfo.notes}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">
                      Reason for Reservation
                    </span>
                    <span className="bookingtext">{personInfo.reason}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CarConfirmation;
