import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import CarCustomerDetailForm from "pages/spacex/cars/CarCustomerDetailForm";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import "react-dates/lib/css/_datepicker.css";
import "assets/scss/Car.scss";

const CarPersonalInfo = props => {
  const [isConfirmRedirect, setIsConfirmRedirect] = useState(false);
  const [pageContent] = useState({});
  const [countryOfPassport, setCountryOfPassport] = useState(false);
  const [startDate] = useState(props.match.params.startDate);
  const [unitId] = useState(props.match.params.unitId);
  const [vehicleId] = useState(props.match.params.vehicleId);
  const [dropOffArr] = useState(
    props.location.state.dropOffArr ? props.location.state.dropOffArr : []
  );
  const [pickUpArr] = useState(
    props.location.state.pickUpArr ? props.location.state.pickUpArr : []
  );
  const [maxRentalPeriod] = useState(
    props.location.state.maxRentalPeriod
      ? props.location.state.maxRentalPeriod
      : 7
  );
  const [handleValidDate] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [personInfo, setPersonInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  });

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  });

  const handleCarReservation = data => {
    axios
      .post("/api/vehicles/book", {
        vehicle_id: vehicleId,
        unit_id: unitId,
        start_date: data.start_date,
        start_time: data.start_time,
        return_date: data.return_date,
        est_return_date: data.return_date,
        return_time: data.return_time,
        employee_name: data.employee_name,
        email: data.email,
        phone: data.phone,
        notes: data.notes,
        reason: data.reason,
        pickup_location: data.pickup_location,
        dropoff_location: data.dropoff_location,
        name: data.name
      })
      .then(response => {
        if (response.data && response.data.status !== "200") {
          setErrMsg("We are sorry, your booking is not successful.");
          return;
        }

        setPersonInfo({
          start_date: data.start_date,
          employee_name: data.employee_name,
          pickup_location: data.pickup_location,
          dropoff_location: data.dropoff_location,
          start_time: data.start_time,
          return_date: data.return_date,
          est_return_date: data.return_date,
          return_time: data.return_time,
          email: data.email,
          phone: data.phone,
          notes: data.notes,
          reason: data.reason,
          name: data.name
        });
        setIsConfirmRedirect(true);
      })
      .catch(error => {
        console.log(error);
        setErrMsg("We are sorry, your booking is not successful.");
      });
  };

  const handleCountryOfPassport = value => {
    setCountryOfPassport(value);
  };

  if (isConfirmRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: "/car/confirmation",
          search: window.location.search,
          state: {
            personInfo: {
              start_date: personInfo.start_date,
              start_time: personInfo.start_time,
              return_date: personInfo.return_date,
              est_return_date: personInfo.return_date,
              return_time: personInfo.return_time,
              employee_name: personInfo.employee_name,
              dropoff_location: personInfo.dropoff_location,
              pickup_location: personInfo.pickup_location,
              email: personInfo.email,
              phone: personInfo.phone,
              notes: personInfo.notes,
              reason: personInfo.reason,
              name: personInfo.name
            }
          }
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <SpacexNavbar />
      <CarCustomerDetailForm
        handleReservation={handleCarReservation}
        pageType="customerDetails"
        pageContent={pageContent}
        countryOfPassport={countryOfPassport}
        handleCountryOfPassport={handleCountryOfPassport}
        handleValidDate={handleValidDate}
        errMsg={errMsg}
        vehicleId={vehicleId}
        unitId={unitId}
        startDate={startDate}
        dropOffArr={dropOffArr}
        pickUpArr={pickUpArr}
        maxRentalPeriod={maxRentalPeriod}
      />
    </React.Fragment>
  );
};

export default CarPersonalInfo;
