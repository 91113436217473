import React from "react";
import "assets/scss/SleepDoorCode.scss";
import SleepImage from "./SleepImage";

const SleepDoorCode = () => {
  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        <h2 className="title">Your Reservation Details</h2>
        <p className="booking-id">
          You will be staying in <span>{`{room_type}`}</span> 
          <br /> Room number <span>{`{room_number}`}</span>
          <br /> Door code <span>{`{code}`}</span>
        </p>
        <p className="booking-id">
          Google Maps Pin{" "}
          <span>
            <a
              href={`{room.map_url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>
          </span>
        </p>
        <br />
        <div className="warning-text">
          <b>
            For Airstreams: A door key is available for you to lock your
            airstream if you prefer. Please use the door code above to access
            the lock box. Lock box is located inside your airstream, inside the
            closet.
          </b>
          <br />
          <br />
          <span className="star-text">
            **You are responsible to return the key before checking out
            <br />
            **Any lost keys must be reported to StarbaseLiving immediately
          </span>
        </div>
        {<SleepImage />}
      </div>
    </div>
  );
};

export default SleepDoorCode;
